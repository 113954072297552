import { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Lister from "../../../../components/Lister/Lister";
import { formatDateDifference, formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { getEvaluationLink, getStatus } from "./UserExams.function";

const UserExams = ({
  user_data,
  loaded,
  language,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
}): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [selectedComment, setSelectedComment] = useState({
    content: "",
    date: "",
    corrector_name: "",
    corrector_id: null,
  });
  const nowDate = new Date();

  return (
    <div className="w-100">
      <h2>{translate(language, "PAGES.USER.EXAM_TABLE.TITLE")}</h2>
      {loaded ? (
        user_data.user_exams.length > 0 ? (
          <Lister
            items={user_data.user_exams.map((user_exam: any) => ({
              ...user_exam,
              token: user_exam.id.toString(),
              creation_date: user_exam.dt_sent,
              start_date: user_exam.dt_start,
              duration: formatDateDifference(user_exam.dt_start, user_exam.dt_ended || nowDate),
              status: user_exam.status.replace(/_/g, " "),
            }))}
            columns={[
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.NAME"),
                key: "name",
              },
              { header: translate(language, "PAGES.USER.EXAM_TABLE.TOKEN"), key: "token" },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.CREATION_DATE"),
                key: "creation_date",
                sortType: "date",
                render: (item: any) => (
                  <div>
                    <OverlayTrigger
                      overlay={<Tooltip>{formatDateHumanly(language, item["creation_date"], true)}</Tooltip>}
                    >
                      <span>{formatDateHumanly(language, item["creation_date"])}</span>
                    </OverlayTrigger>
                  </div>
                ),
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.START_DATE"),
                key: "start_date",
                sortType: "date",
                render: (item: any) =>
                  item["start_date"] === null ? (
                    <span>-</span>
                  ) : (
                    <OverlayTrigger
                      overlay={<Tooltip>{formatDateHumanly(language, item["start_date"], true)}</Tooltip>}
                    >
                      <span>{formatDateHumanly(language, item["start_date"])}</span>
                    </OverlayTrigger>
                  ),
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.DURATION"),
                key: "duration",
                render: (item: any) => {
                  if (item.type === "upload" || item.status === "created") {
                    return "-";
                  }
                  return item.duration;
                },
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.STATUS"),
                key: "status",
                render: (item: any) => <span className={`p-1 rounded ${getStatus(item.status)}`}>{item.status}</span>,
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.ACTIONS"),
                key: "actions",
                render: (item: any) => (
                  <div className="d-flex align-items-baseline">
                    <a
                      className="text-decoration-none"
                      href={getEvaluationLink(item.id)}
                      title="Go to Beta"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="dst-icon-link-external-02"></i>
                    </a>
                    {item.comment && (
                      <span
                        className={`dst-icon-message-${
                          item.status === "success" ? "check" : "alert"
                        }-square d-block s-regular gray-font clickable ms-2`}
                        onClick={() => {
                          setSelectedComment({
                            content: item.comment!,
                            date: item.dt_corrected || "",
                            corrector_name: item.corrector.name,
                            corrector_id: item.corrector.id,
                          });
                          setShowModal(true);
                        }}
                      />
                    )}
                  </div>
                ),
              },
            ]}
            mock={!loaded}
          />
        ) : (
          <p>{translate(language, "PAGES.USER.EXAM_TABLE.NO_EXAM")}</p>
        )
      ) : (
        <Lister
          columns={[
            { header: translate(language, "PAGES.USER.EXAM_TABLE.NAME"), key: "name" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.TOKEN"), key: "token" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.CREATION_DATE"), key: "creation_date" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.START_DATE"), key: "start_date" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.DURATION"), key: "duration" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.STATUS"), key: "status" },
          ]}
          mock={true}
        />
      )}
      <Modal
        className="d-flex justify-content-center align-items-center"
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
      >
        <Modal.Header className="mw-100" closeButton>
          <Modal.Title className="me-3 me-md-5">
            <h3>{translate(language, "PAGES.USER.EXAM_TABLE.MODAL.TITLE")}</h3>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-break d-flex justify-content-start p-2 bg-light">
            <p className="m-regular" dangerouslySetInnerHTML={{ __html: selectedComment.content }}></p>
          </div>
          <div className="d-flex">
            {selectedComment.corrector_name && (
              <div className="text-break d-flex justify-content-end xs-bold pt-3">
                {translate(language, "PAGES.USER.EXAM_TABLE.MODAL.BY")}
                <span
                  className="ms-1 clickable text-decoration-underline"
                  onClick={() => {
                    setShowModal(false);
                    navigate(`/user/${selectedComment.corrector_id}`);
                  }}
                >
                  {selectedComment.corrector_name}
                </span>
              </div>
            )}
            <div className="text-break d-flex justify-content-end xs-bold pt-3 ms-1">
              {translate(language, "PAGES.USER.EXAM_TABLE.MODAL.THE")}{" "}
              {formatDateHumanly(language, selectedComment.date)}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserExams;
