import React, { useEffect, useState } from "react";
import { Form, Modal, Placeholder } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import DstConfirm from "../../../../components/DstConfirm/DstConfirm";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import Popup from "../../../../components/Popup/Popup";
import { getAgeHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { createResource, deleteResource, editResource, fetchResources, styleUserRow } from "./CohortResources.function";

const CohortResources = ({
  cohortId,
  language,
  showPopup,
  closePopup,
}: {
  cohortId: string;
  language: string;
  showPopup: boolean;
  closePopup: Function;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cohortResources, setCohortResources] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resourceName, setResourceName] = useState("");
  const [resourceUrl, setResourceUrl] = useState("");
  const [resourceIsPublic, setResourceIsPublic] = useState(false);
  const [resourceIsPedagogical, setResourceIsPedagogical] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentResource, setCurrentResource] = useState<any>(null);
  const [hasPedagogicalResource, setHasPedagogicalResource] = useState(false);

  const columns = [
    { key: "name", name: translate(language, "PAGES.COHORT.RESOURCES.NAME") },
    {
      key: "dt_added",
      name: translate(language, "PAGES.COHORT.RESOURCES.CREATION_DATE"),
      render: (cohortResource: any) =>
        cohortResource.dt_added ? getAgeHumanly(language, new Date(cohortResource.dt_added).getTime()) : "N/A",
    },
    {
      key: "dt_updated",
      name: translate(language, "PAGES.COHORT.RESOURCES.UPDATE_DATE"),
      render: (cohortResource: any) =>
        cohortResource.dt_updated ? getAgeHumanly(language, new Date(cohortResource.dt_updated).getTime()) : "N/A",
    },
    {
      key: "url",
      name: translate(language, "PAGES.COHORT.RESOURCES.LINK"),
      render: (cohortResource: any) => (
        <span className="d-flex align-items-center">
          {cohortResource.url}{" "}
          <a
            className="ms-2 dst-icon-link-external-02 text-decoration-none"
            href={cohortResource.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <React.Fragment />
          </a>
        </span>
      ),
    },
    {
      key: "author",
      name: translate(language, "PAGES.COHORT.RESOURCES.OWNER"),
      render: (cohortResource: any) => cohortResource.author.email,
    },
    {
      key: "is_public",
      name: translate(language, "PAGES.COHORT.RESOURCES.PUBLIC"),
      render: (cohortResource: any) =>
        cohortResource.is_public ? (
          <i className="dst-icon-check green-font" />
        ) : (
          <i className="dst-icon-x dark-red-font" />
        ),
    },
    {
      key: "action",
      name: translate(language, "PAGES.COHORT.RESOURCES.ACTIONS.TITLE"),
      render: (cohortResource: any) => (
        <DstButton
          btnClass="delete-button d-flex align-items-center"
          value={translate(language, "PAGES.COHORT.RESOURCES.ACTIONS.DELETE")}
          variant="danger"
          btnSize="sm"
          btnImageBefore={<span className="dst-icon-trash-01 pe-1" />}
          clickFunction={(_event: any) => {
            setShowDeleteModal(true);
            setCurrentResource(cohortResource);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchResources(cohortId, setCohortResources, setHasPedagogicalResource, language, () => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddResourceClick = () => {
    setIsEditing(false);
    setResourceName("");
    setResourceUrl("");
    setResourceIsPublic(false);
    setResourceIsPedagogical(false);
    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    deleteResource(currentResource.id, language, () => {
      setShowDeleteModal(false);
      setLoading(true);
      fetchResources(cohortId, setCohortResources, setHasPedagogicalResource, language, () => setLoading(false));
    });
  };

  const handleConfirmClick = () => {
    if (
      resourceIsPublic &&
      !window.confirm(translate(language, "PAGES.COHORT.RESOURCES.MODAL.PUBLIC_RESOURCE_WARNING"))
    ) {
      return;
    }

    if (isEditing && currentResource) {
      editResource(
        cohortId,
        currentResource.id,
        language,
        setSubmitLoading,
        resourceName,
        resourceUrl,
        resourceIsPublic,
        resourceIsPedagogical,
        () => {
          setLoading(true);
          fetchResources(cohortId, setCohortResources, setHasPedagogicalResource, language, () => setLoading(false));
          setShowModal(false);
          closePopup();
        }
      );
    } else {
      createResource(
        cohortId,
        language,
        setSubmitLoading,
        resourceName,
        resourceUrl,
        resourceIsPublic,
        resourceIsPedagogical,
        () => {
          setLoading(true);
          fetchResources(cohortId, setCohortResources, setHasPedagogicalResource, language, () => setLoading(false));
          setShowModal(false);
          closePopup();
        }
      );
    }
  };

  if (loading) {
    return (
      <Placeholder animation="glow">
        <div className="mx-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Placeholder className="me-3 align-content-center mb-0" as="h2" xs={12} md={6} />
              <Placeholder as="input" xs={12} md={4} />
            </div>
            <DstButton mock btnWidth="100px" />
          </div>
        </div>
      </Placeholder>
    );
  }
  return (
    <React.Fragment>
      <div className="mx-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            <h2 className="me-3 align-content-center mb-0">{translate(language, "PAGES.COHORT.RESOURCES.TITLE")}</h2>
            <DstTableSearch
              data={cohortResources}
              searchableColumns={columns.map((col) => col.key)}
              onSearch={setFilteredData}
            />
          </div>
          <DstButton
            btnImageBefore={<i className="dst-icon-plus me-2 align-text-bottom" />}
            value={translate(language, "PAGES.COHORT.RESOURCES.BUTTON_ADD_RESOURCES")}
            clickFunction={handleAddResourceClick}
          />
        </div>
        <DstTable
          data={filteredData}
          columns={columns}
          classLine={(elem) => styleUserRow(elem)}
          onClick={(event, cohortResource: any) => {
            if ((event.target as HTMLElement).closest(".delete-button")) {
              return;
            }
            setIsEditing(true);
            setCurrentResource(cohortResource);
            setResourceName(cohortResource.name);
            setResourceUrl(cohortResource.url);
            setResourceIsPublic(cohortResource.is_public);
            setResourceIsPedagogical(cohortResource.is_pedagogical_path);
            setShowModal(true);
          }}
        />
      </div>
      <DstConfirm
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
        title={translate(language, "PAGES.COHORT.RESOURCES.MODAL_DELETION.CONFIRM_DELETE_TITLE")}
        messageConfirm={translate(language, "PAGES.COHORT.RESOURCES.MODAL_DELETION.CONFIRM_DELETE_TEXT")}
        confirmTextButton={translate(language, "PAGES.COHORT.RESOURCES.MODAL_DELETION.CONFIRM_DELETE_BTN")}
      />
      <Popup
        show={showModal || showPopup}
        onClose={() => {
          setShowModal(false);
          closePopup();
        }}
        modalTitle={
          <div className="blue-font">
            <i className="dst-icon-file-05" />{" "}
            {translate(
              language,
              isEditing ? "PAGES.COHORT.RESOURCES.MODAL.EDIT_TITLE" : "PAGES.COHORT.RESOURCES.MODAL.CREATE_TITLE"
            )}
          </div>
        }
        scrollable={true}
      >
        <Form>
          <Form.Group>
            <Form.Label>{translate(language, "PAGES.COHORT.RESOURCES.MODAL.RESOURCES_LABEL_NAME")}</Form.Label>
            <Form.Control
              type="text"
              value={resourceName}
              onChange={(e) => setResourceName(e.target.value)}
              placeholder={translate(language, "PAGES.COHORT.RESOURCES.MODAL.RESOURCES_PLACEHOLDER_NAME")}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.RESOURCES.MODAL.RESOURCES_LABEL_FILE_LINK")}</Form.Label>
            <Form.Control
              type="text"
              value={resourceUrl}
              onChange={(e) => setResourceUrl(e.target.value)}
              placeholder={translate(language, "PAGES.COHORT.RESOURCES.MODAL.RESOURCES_PLACEHOLDER_FILE_LINK")}
            />
          </Form.Group>
          <Form.Check
            className="mt-4"
            type="checkbox"
            id="is-public"
            label={
              <label htmlFor="is-public">
                {translate(language, "PAGES.COHORT.RESOURCES.MODAL.RESOURCES_IS_PUBLIC_CHECK")}
              </label>
            }
            checked={resourceIsPublic}
            onChange={(event) => {
              setResourceIsPublic(event.target.checked);
            }}
          />
          <Form.Check
            className="mt-4"
            type="checkbox"
            id="is-pedagogical"
            label={
              <label htmlFor="is-pedagogical">
                {translate(language, "PAGES.COHORT.RESOURCES.MODAL.RESOURCES_IS_PEDAGOGICAL_CHECK")}
              </label>
            }
            checked={resourceIsPedagogical}
            onChange={(event) => {
              setResourceIsPedagogical(event.target.checked);
            }}
            disabled={
              !isEditing ? hasPedagogicalResource : !currentResource?.is_pedagogical_path && hasPedagogicalResource
            }
          />
        </Form>
        <Modal.Footer className="pt-3 border-top-0">
          <DstButton
            variant="secondary"
            value={translate(language, "PAGES.COHORT.RESOURCES.MODAL.BUTTON_CANCEL")}
            clickFunction={() => {
              setShowModal(false);
              closePopup();
            }}
          />
          <DstButton
            value={translate(language, "PAGES.COHORT.RESOURCES.MODAL.BUTTON_CONFIRM")}
            loading={submitLoading}
            clickFunction={handleConfirmClick}
          />
        </Modal.Footer>
      </Popup>
    </React.Fragment>
  );
};

export default CohortResources;
