import React, { useEffect, useState } from "react";
import { Dropdown, Form, InputGroup, OverlayTrigger, Placeholder, Tooltip } from "react-bootstrap";

import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import { MultiFilterDropdownProps, tagsDropdown } from "./MultiFilterDropdown.d";
import "./MultiFilterDropdown.scss";

const _prepareTags = (tags: string[] | tagsDropdown[]): tagsDropdown[] => {
  return tags.map((tag: string | tagsDropdown) => {
    if (typeof tag == "string") {
      return {
        display: tag,
        value: tag,
      };
    } else {
      return tag;
    }
  });
};

const MultiFilterDropdown = ({
  buttonWidth = "150px",
  initialStateText,
  inputLabel,
  inputPlaceholder,
  inputTitle,
  mock = false,
  onSelectTags,
  showSearchBar,
  tags,
}: MultiFilterDropdownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<(string | number)[]>([]);
  const [selectedTagsText, setSelectedTagsText] = useState<string>("");
  const { language } = useSession();

  initialStateText = initialStateText || translate(language, "COMPONENTS.MULTI_FILTER_DROPDOWN.ALL");
  inputLabel = inputLabel || translate(language, "COMPONENTS.MULTI_FILTER_DROPDOWN.INPUT_LABEL");
  inputPlaceholder = inputPlaceholder || translate(language, "COMPONENTS.MULTI_FILTER_DROPDOWN.INPUT_PLACEHOLDER");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleTagCheckboxChange = (tag: tagsDropdown) => {
    setSelectedTags((prevTags: (string | number)[]): (string | number)[] => {
      const updatedTags = prevTags.includes(tag.value)
        ? prevTags.filter((t: string | number) => t !== tag.value)
        : [...prevTags, tag.value];
      onSelectTags(updatedTags);
      return updatedTags;
    });
  };

  const filteredTags = _prepareTags(tags).filter((tag: tagsDropdown) =>
    tag.display.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setSelectedTagsText(
      filteredTags
        .filter((tag) => selectedTags.includes(tag.value))
        .map((tag) => tag.display)
        .join(", ")
    );
  }, [filteredTags, selectedTags]);

  const renderTags = () =>
    filteredTags.map((tag, index) => (
      <div key={`${tag.value}-${index}`} onClick={() => handleTagCheckboxChange(tag)}>
        <Form.Check
          className="d-flex justify-content-between flex-row-reverse ps-0 s-regular"
          type="checkbox"
          label={tag.display}
          checked={selectedTags.includes(tag.value)}
          onChange={() => {}}
        />
      </div>
    ));

  const renderDropdownToggle = () => (
    <Dropdown.Toggle variant="light" className="s-bold text-start d-flex">
      <div>
        <div className="dark-gray-font"> {inputTitle}</div>
        <div className="text-truncate" style={{ width: buttonWidth }}>
          {selectedTagsText || <span> {initialStateText}</span>}
        </div>
      </div>
      <div className="align-self-center ms-2">
        <i className="dst-icon-chevron-down" />
      </div>
    </Dropdown.Toggle>
  );

  if (mock) {
    return (
      <Placeholder animation="glow">
        <Placeholder.Button
          style={{
            width: buttonWidth,
            height: 50,
          }}
          className="s-bold"
          aria-hidden="true"
        />
      </Placeholder>
    );
  }

  return (
    <Dropdown className="multi-filter-dropdown">
      {selectedTagsText.length > 0 ? (
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">{selectedTagsText}</Tooltip>}>
          {renderDropdownToggle()}
        </OverlayTrigger>
      ) : (
        renderDropdownToggle()
      )}
      <Dropdown.Menu style={{ minWidth: "300px" }}>
        {showSearchBar && (
          <Form.Group className="m-3">
            <InputGroup>
              <InputGroup.Text className="s-regular">{inputLabel}</InputGroup.Text>
              <Form.Control
                className="s-regular"
                type="text"
                placeholder={inputPlaceholder}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </Form.Group>
        )}
        <div className="my-2 m-3 scrollable size-dropdown">{renderTags()}</div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiFilterDropdown;
