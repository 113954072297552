import QuillResizeImage from "quill-resize-image";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Placeholder,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import ReactQuill, { Quill } from "react-quill";
import { useLocation } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import Search from "../../components/DstIcons/Search";
import DstTabs from "../../components/DstTabs/DstTabs";
import Lister from "../../components/Lister/Lister";
import { ListItem } from "../../components/Lister/Lister.d";
import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import MultiSelectDropdown from "../../components/MultiSelectDropdown/MultiSelectDropdown";
import MultiSelectLiveDropdown from "../../components/MultiSelectLiveDropdown/MultiSelectLiveDropdown";
import Popup from "../../components/Popup/Popup";
import { useSession } from "../../contexts/SessionContext";
import { TemplateMessage } from "../../models/TemplateMessage";
import { PermissionComponent } from "../../services/PermissionGuard";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { AddTemplateForm, EditTemplateForm, SelectedTemplate } from "./Messages.component";
import { ImageObject } from "./Messages.d";
import {
  RECIPIENT_PAGING_SIZE,
  deleteTemplate,
  handleAddTemplateSubmit,
  handleCancel,
  handleTemplateSelect,
  listItemMessages,
  loadMessagesAndTemplates,
  messagesRecipientTypes,
  resumeReceivers,
  selectForEditing,
  sendMessage,
  startAddingNewTemplate,
  submitEdit,
  summarizeReceivers,
} from "./Messages.function";
import "./Messages.scss";
import { CheatsheetOffCanvas, restrictedPlaceholders } from "./components/MessagesCheatsheet";

Quill.register("modules/resize", QuillResizeImage);

const Messages = () => {
  const { state } = useLocation() as any;
  const preselectedCohort =
    state?.cohortId && state?.cohortName
      ? [
          {
            category: "cohorts",
            value: {
              id: state.cohortId,
              name: state.cohortName,
            },
          },
        ]
      : [];

  const [images, setImages] = useState<Array<ImageObject>>([]);
  const { language, user_id, first_name, last_name } = useSession();
  const [messages, setMessages] = useState<Array<any>>([]);
  const [templates, setTemplates] = useState<TemplateMessage[]>([]);
  const [loaded, setLoaded] = useState<any>({
    messages: false,
    templates: false,
  });
  const [activeKey, setActiveKey] = useState("send-message");
  const [message, setMessage] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [authorFilter, setAuthorFilter] = useState(-1);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedItemsAlias, setSelectedItemsAlias] = useState<any[]>([]);
  const [isMultiSelectLiveDropdownDisabled, setIsMultiSelectLiveDropdownDisabled] = useState(false);
  const [isMultiSelectDropdownDisabled, setIsMultiSelectDropdownDisabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateMessage | null>(null);
  const [templateName, setTemplateName] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [editingTemplateName, setEditingTemplateName] = useState("");
  const [editingTemplateContent, setEditingTemplateContent] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [isAddingNewTemplate, setIsAddingNewTemplate] = useState(false);
  const [recipientsVisibleCount, setRecipientsVisibleCount] = useState(RECIPIENT_PAGING_SIZE);
  const reactQuillRef = useRef<ReactQuill>(null);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();
    input.onchange = () => {
      const { files } = input;
      if (input !== null && files !== null) {
        const filesArray = Array.from(files);
        const quill = reactQuillRef.current?.getEditor();
        filesArray.forEach((image) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const range = quill?.getSelection(true);
            if (range) {
              const imgSrc = e.target?.result;
              quill?.insertEmbed(range.index, "image", imgSrc);
              const imgElement = quill?.root.querySelector(`img[src="${imgSrc}"]`);
              setImages((prevImages) => [...prevImages, { file: image, element: `${imgElement?.outerHTML}` }]);
              quill?.setSelection(range.index + 1, 0);
            }
          };
          reader.readAsDataURL(image);
        });
      }
    };
  }, [reactQuillRef]);

  const quillToolbarModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image"],
          ["code-block"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
      resize: {
        locale: {},
      },
    }),
    [imageHandler]
  );
  const [showCheatsheetPopup, setShowCheatsheetPopup] = useState(false);
  const [btnSendDisable, setBtnSendDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    loadMessagesAndTemplates(setMessages, setTemplates, setLoaded);
  }, []);
  const handleSelect = (k: any) => setActiveKey(k);

  const handleOpenLink = (key: string, value: string) => {
    const modifiedKey = key.endsWith("s") ? key.slice(0, -1) : key;

    if (key !== "aliases") {
      return () => window.open(`/${modifiedKey}/${value}`);
    }
    return undefined;
  };

  const handleSelectTemplate = (templateId: number) => {
    const template = templates.find((t) => t.id === templateId);

    if (template) {
      setMessage(template.content);
      setSelectedTemplateId(templateId);
    }
  };

  useEffect(() => {
    const allItemsAreCohorts = selectedItems.every((item) => item.category === "cohorts");
    const containsRestrictedPlaceholder = restrictedPlaceholders.some((placeholder) => message.includes(placeholder));

    if (containsRestrictedPlaceholder && !allItemsAreCohorts) {
      setErrorMessage(translate(language, "PAGES.MESSAGES.RESTRICTED_PLACEHOLDER_ERROR"));
      setBtnSendDisable(true);
    } else {
      setErrorMessage("");
      setBtnSendDisable(false);
    }
    // eslint-disable-next-line
  }, [message, selectedItems]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState<{
    author: any;
    content: string;
    receivers: any[];
    parameters: Record<string, string | string[]>;
    identified_cohorts: any[];
  }>({
    author: null,
    content: "",
    receivers: [],
    parameters: {},
    identified_cohorts: [],
  });

  const openMessagePopup = (messageId: number) => {
    const message = messages.find((message) => message.id === messageId);

    if (message) {
      const { author, content, receivers, parameters, identified_cohorts } = message;
      setPopupMessage({
        author,
        content,
        receivers,
        parameters: JSON.parse(
          parameters.replace(/('(?=(,\s*')))|('(?=:))|((?<=([:,[]\s*))')|((?<={)')|('(?=]|}))/g, '"')
        ),
        identified_cohorts: identified_cohorts || [],
      });
      setShowPopup(true);
    }
  };

  const columns = [
    { header: translate(language, "PAGES.MESSAGES.AUTHOR_HEADER"), key: "author_name" },
    { header: translate(language, "PAGES.MESSAGES.RECIPIENTS_HEADER"), key: "name" },
    { header: translate(language, "PAGES.MESSAGES.CONTENT_HEADER"), key: "content" },
    {
      header: translate(language, "PAGES.MESSAGES.CREATION_DATE_HEADER"),
      key: "date",
      render: (item: any) => (
        <div>
          <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
            <span>{formatDateHumanly(language, item["date"])}</span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const filteredItems: ListItem[] = useMemo(() => {
    const filteredByAuthor = listItemMessages(
      language,
      messages.filter((message) => [-1, message.author.id].includes(authorFilter))
    );

    return filteredByAuthor.filter((item) =>
      Object.values(item).join(" ").toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [language, messages, authorFilter, searchQuery]);

  const tabsData = [
    {
      id: "send-message",
      title: (
        <React.Fragment>
          <span className="dst-icon-send-03 me-2" />
          {translate(language, "PAGES.MESSAGES.SEND_MESSAGE_TAB_TITLE")}
        </React.Fragment>
      ),
      content: (
        <PermissionComponent neededPermission={[{ methods: ["POST"], route: "/messages" }]} showFallBack>
          <div className="m-regular border rounded p-4">
            <Row className="mb-2">
              <Col className="m-regular">
                <InputGroup className="mb-3 custom-input-group multi-select-dropdown">
                  <InputGroup.Text>
                    <span className="dst-icon-users-03" />
                  </InputGroup.Text>
                  <MultiSelectLiveDropdown
                    category={["users", "cohorts"]}
                    label={`${translate(language, "PAGES.MESSAGES.TO_LABEL")} ${resumeReceivers(selectedItems)}`}
                    language={language}
                    mock={!(loaded.messages && loaded.templates)}
                    mockClass="rounded-0 rounded-end"
                    onConfirm={(selectedRecipients: any[]) => {
                      setSelectedItems(selectedRecipients);
                      setIsMultiSelectDropdownDisabled(selectedRecipients.length > 0);
                    }}
                    showFilterButton={true}
                    toggleClass="min-w-100px m-regular"
                    disabled={isMultiSelectLiveDropdownDisabled}
                    clearItemsOnSend={isSending}
                    initialSelectedItems={preselectedCohort}
                  />
                  <MultiSelectDropdown
                    label={`${translate(language, "PAGES.MESSAGES.TO_LABEL")} ${summarizeReceivers(
                      selectedItemsAlias
                    )}`}
                    displayItem={(item: any) => (
                      <div>{translate(language, `PAGES.MESSAGES.RECIPIENT_TYPES.${item.name.toUpperCase()}`)}</div>
                    )}
                    badgeItemProperty="name"
                    items={messagesRecipientTypes}
                    onConfirm={(selectedRecipients: any[]) => {
                      setSelectedItemsAlias(selectedRecipients);
                      setIsMultiSelectLiveDropdownDisabled(selectedRecipients.length > 0);
                    }}
                    language={language}
                    mock={!(loaded.messages && loaded.templates)}
                    showAlwaysConfirm={true}
                    disabled={isMultiSelectDropdownDisabled}
                    clearItemsOnSend={isSending}
                  />
                </InputGroup>
              </Col>
              <Col className="text-end d-flex justify-content-end">
                <Form.Group
                  controlId="templateSelect"
                  className="border d-flex rounded custom-input-group"
                  style={{ minWidth: "fit-content" }}
                >
                  <InputGroup className="border-0 rounded">
                    <InputGroup.Text className="border-0">
                      <span className="dst-icon-file-06" />
                    </InputGroup.Text>
                    {loaded["templates"] ? (
                      <FormControl
                        as="select"
                        value={selectedTemplateId || ""}
                        onChange={(e) => handleSelectTemplate(+e.target.value)}
                        className="border-0 border-start m-regular"
                        style={{
                          appearance: "none",
                          WebkitAppearance: "none",
                          paddingLeft: "0.5em",
                        }}
                      >
                        <option value="">{translate(language, "PAGES.MESSAGES.CHOOSE_TEMPLATE")}</option>
                        {templates.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.name}
                          </option>
                        ))}
                      </FormControl>
                    ) : (
                      <Placeholder animation="glow">
                        <Placeholder
                          as="select"
                          className="form-control border-0 border-start m-regular"
                          style={{
                            width: "20ch",
                            paddingLeft: "0.5em",
                            height: "100%",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                        />
                      </Placeholder>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <ReactQuill
              value={message || ""}
              onChange={setMessage}
              placeholder={translate(language, "PAGES.MESSAGES.WRITE_MESSAGE_PLACEHOLDER")}
              className={`mb-3 ${errorMessage ? "error-border" : ""}`}
              preserveWhitespace
              ref={reactQuillRef}
              modules={quillToolbarModules}
            />
            {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
            <Row>
              <Col className="m-regular text-start">
                <DstButton
                  btnClass="m-bold"
                  variant="secondary"
                  clickFunction={() => setShowCheatsheetPopup((show) => !show)}
                  value={translate(language, "PAGES.MESSAGES.CHEATSHEET.BUTTON_TITLE")}
                  btnImageAfter={<i className="dst-icon-help-hexagon ms-1"></i>}
                />
              </Col>
              <Col className="m-regular text-end">
                <DstButton
                  btnClass="m-bold"
                  variant="primary"
                  disabled={isSending || btnSendDisable}
                  clickFunction={() => {
                    setIsSending(true);
                    sendMessage(
                      selectedItems,
                      selectedItemsAlias,
                      images,
                      message,
                      language,
                      setActiveKey,
                      setMessage,
                      setMessages,
                      setTemplates,
                      setLoaded,
                      setSelectedItems,
                      setSelectedItemsAlias,
                      () => setIsSending(false)
                    );
                  }}
                  btnImageAfter={
                    isSending ? <Spinner className="ms-4" size="sm" /> : <span className="dst-icon-send-03 ms-4" />
                  }
                  value={translate(language, "PAGES.MESSAGES.SEND_MESSAGE")}
                />
              </Col>
              <CheatsheetOffCanvas
                show={showCheatsheetPopup}
                onHide={() => setShowCheatsheetPopup(false)}
                language={language}
              />
            </Row>
          </div>
        </PermissionComponent>
      ),
    },
    {
      id: "manage-message",
      title: (
        <React.Fragment>
          <span className="dst-icon-git-commit me-2" />
          {translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES_TAB_TITLE")}
        </React.Fragment>
      ),
      content: (
        <PermissionComponent neededPermission={[{ methods: ["POST"], route: "/messages/templates" }]} showFallBack>
          <div>
            <Row>
              <Col lg={3}>
                <h3>{translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.ALL_TEMPLATE")}</h3>
                <div className="overflow-auto max-h-template">
                  {templates.map((template) => (
                    <div
                      key={template.id}
                      className={`template-item clickable m-regular ${
                        selectedTemplate && selectedTemplate.id === template.id ? "template-item-active" : ""
                      }`}
                      onClick={() =>
                        handleTemplateSelect(
                          template,
                          setSelectedTemplate,
                          setShowEditForm,
                          setEditingTemplateId,
                          setEditingTemplateName,
                          setEditingTemplateContent,
                          setIsAddingNewTemplate
                        )
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleTemplateSelect(
                            template,
                            setSelectedTemplate,
                            setShowEditForm,
                            setEditingTemplateId,
                            setEditingTemplateName,
                            setEditingTemplateContent,
                            setIsAddingNewTemplate
                          );
                        }
                      }}
                      tabIndex={0}
                    >
                      <div className="py-2">
                        <div className="px-2">{template.name}</div>
                        <div className="px-2 xs-regular smoke-gray-font">
                          {formatDateHumanly(language, template.dt_last_updated || template.dt_created)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2 mt-lg-3 mb-3 mb-lg-0">
                  <DstButton
                    btnClass="d-flex align-items-center"
                    value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.ADD")}
                    variant="outline-primary"
                    btnImageAfter={<span className="dst-icon-plus ms-2"></span>}
                    clickFunction={() =>
                      startAddingNewTemplate(
                        setIsAddingNewTemplate,
                        setEditingTemplateId,
                        setTemplateName,
                        setTemplateContent,
                        setShowEditForm,
                        setSelectedTemplate,
                        language
                      )
                    }
                  />
                </div>
              </Col>
              <Col>
                {isAddingNewTemplate && (
                  <AddTemplateForm
                    language={language}
                    templateName={templateName}
                    templateContent={templateContent}
                    setTemplateName={setTemplateName}
                    setTemplateContent={setTemplateContent}
                    handleAddTemplateSubmit={() =>
                      handleAddTemplateSubmit(
                        templateName,
                        templateContent,
                        setTemplateName,
                        setTemplateContent,
                        setIsAddingNewTemplate,
                        setSelectedTemplate,
                        setShowEditForm,
                        loadMessagesAndTemplates,
                        setMessages,
                        setTemplates,
                        setLoaded,
                        language
                      )
                    }
                    handleCancel={() =>
                      handleCancel(
                        setTemplateName,
                        setTemplateContent,
                        setShowEditForm,
                        setIsAddingNewTemplate,
                        setEditingTemplateId
                      )
                    }
                  />
                )}
                {showEditForm && editingTemplateId && (
                  <EditTemplateForm
                    editingTemplateName={editingTemplateName}
                    language={language}
                    selectedTemplate={selectedTemplate}
                    setEditingTemplateName={setEditingTemplateName}
                    editingTemplateContent={editingTemplateContent}
                    setEditingTemplateContent={setEditingTemplateContent}
                    submitEdit={() =>
                      submitEdit(
                        selectedTemplate,
                        {
                          full_name: `${first_name} ${last_name}`,
                          id: user_id,
                        },
                        editingTemplateId,
                        editingTemplateName,
                        editingTemplateContent,
                        setSelectedTemplate,
                        setShowEditForm,
                        loadMessagesAndTemplates,
                        setMessages,
                        setTemplates,
                        setLoaded,
                        language
                      )
                    }
                    handleCancel={() =>
                      handleCancel(
                        setTemplateName,
                        setTemplateContent,
                        setShowEditForm,
                        setIsAddingNewTemplate,
                        setEditingTemplateId
                      )
                    }
                    showForm={showEditForm || (isAddingNewTemplate && editingTemplateId)}
                  />
                )}
                {selectedTemplate && !showEditForm && (
                  <SelectedTemplate
                    selectedTemplate={selectedTemplate}
                    language={language}
                    selectForEditing={(template) =>
                      selectForEditing(
                        template,
                        setEditingTemplateId,
                        setEditingTemplateName,
                        setEditingTemplateContent,
                        setShowEditForm,
                        setIsAddingNewTemplate
                      )
                    }
                    deleteTemplate={() =>
                      selectedTemplate &&
                      deleteTemplate(
                        selectedTemplate.id,
                        setSelectedTemplate,
                        setMessages,
                        setTemplates,
                        setLoaded,
                        language
                      )
                    }
                  />
                )}
                {!isAddingNewTemplate && !selectedTemplate && !showEditForm && (
                  <div>{translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.NOT_SELECTED")}</div>
                )}
              </Col>
            </Row>
          </div>
        </PermissionComponent>
      ),
    },
    {
      id: "message-sent",
      title: (
        <React.Fragment>
          <span className="dst-icon-git-commit me-2" />
          {translate(language, "PAGES.MESSAGES.MESSAGES_SENT_TAB_TITLE")}
        </React.Fragment>
      ),
      content: (
        <div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex">
              <h2 className="blue-font me-2 mb-0">{translate(language, "PAGES.MESSAGES.SENT_MESSAGES_TITLE")}</h2>
              <FormControl
                as="select"
                value={authorFilter || ""}
                onChange={(e) => setAuthorFilter(+e.target.value)}
                className="w-fit"
              >
                <option value={-1}>{translate(language, "PAGES.MESSAGES.ALL_OPTION")}</option>
                <option value={user_id!}>{translate(language, "PAGES.MESSAGES.MINE_OPTION")}</option>
              </FormControl>
            </div>
            <ListerSearch setSearchQuery={setSearchQuery} mock={!loaded.messages} />
          </div>
          <React.Fragment>
            <Lister
              items={filteredItems}
              columns={columns}
              mock={!loaded.messages}
              showHeader
              additionalButton={(item: any) => (
                <span className="clickable xs-regular" onClick={() => openMessagePopup(item.id)}>
                  <Search classes="search-icon m-regular" />
                </span>
              )}
              pagination={true}
            />
          </React.Fragment>
        </div>
      ),
    },
  ];

  return (
    <div className="p-3 p-md-5" id="messages-page">
      <div className="d-flex">
        <div className="me-3 align-self-center">
          <img src="./assets/images/message.png" alt={translate(language, "PAGES.MESSAGES.ENVELOPE_ALT")} height="80" />
        </div>
        <div>
          <h2>{translate(language, "PAGES.MESSAGES.SEND_MESSAGE_TITLE")}</h2>
          <div className="s-regular">
            {" "}
            {translate(language, "PAGES.MESSAGES.COMMUNICATE_EASILY")} <br />
            {translate(language, "PAGES.MESSAGES.PERSONALIZE_MESSAGE")}
          </div>
        </div>
      </div>
      <DstTabs tabsData={tabsData} variant="primary" classes="mt-3" activeKey={activeKey} onSelect={handleSelect} />
      {showPopup && (
        <Popup
          show={showPopup}
          onClose={() => {
            setShowPopup(false);
            setRecipientsVisibleCount(20);
          }}
          modalTitle={translate(language, "PAGES.MESSAGES.MODALE.TITLE")}
          size="xl"
          fullscreen="lg-down"
          scrollable={true}
        >
          <div>
            <h4>{translate(language, "PAGES.MESSAGES.MODALE.AUTHOR")}</h4>
            <DstButton
              btnClass="me-2 mb-3"
              variant="secondary"
              clickFunction={() => window.open(`/user/${popupMessage.author.id}`, "_blank")}
              value={popupMessage.author.email}
              btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
            />
            {popupMessage.receivers && (
              <React.Fragment>
                <h4>{`${translate(language, "PAGES.MESSAGES.MODALE.RECIPIENTS")} (${
                  popupMessage.receivers.length
                })`}</h4>
                <div>
                  {popupMessage.identified_cohorts?.map((cohort: any, index: number) => (
                    <DstButton
                      btnClass="me-2"
                      variant="secondary"
                      key={`cohort-${index}`}
                      clickFunction={() => window.open(`/cohort/${cohort.id}`, "_blank")}
                      value={cohort.name}
                      btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
                    />
                  ))}
                  {popupMessage.identified_cohorts.length > 0 && popupMessage.receivers.length > 0 && ", "}
                  {popupMessage.receivers.slice(0, recipientsVisibleCount).map((receiver: any, index: number) => (
                    <DstButton
                      key={`receiver-${index}`}
                      btnClass="me-2 my-2"
                      variant="secondary"
                      value={receiver.email}
                      btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
                      clickFunction={() => window.open(`/user/${receiver.id}`, "_blank")}
                    />
                  ))}
                  {popupMessage.receivers.length > recipientsVisibleCount && (
                    <Button
                      variant="link"
                      onClick={() => setRecipientsVisibleCount((prevCount) => prevCount + 20)}
                      className="p-0"
                    >
                      {translate(language, "PAGES.MESSAGES.MODALE.MORE_BUTTON")}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
            {popupMessage.parameters && Object.keys(popupMessage.parameters).length > 0 && (
              <React.Fragment>
                <h4 className="mt-3">{translate(language, "PAGES.MESSAGES.MODALE.IN_DETAIL")}</h4>
                <div>
                  {Object.keys(popupMessage.parameters)
                    .filter((key) => ["users", "cohorts", "aliases", "meetings"].includes(key))
                    .map((key) => (
                      <div key={key} className="d-flex">
                        <p className="me-2 d-flex align-items-center">{`${key.charAt(0).toUpperCase()}${key.slice(
                          1
                        )}:`}</p>
                        {(popupMessage.parameters[key] as string).split(",").map((value: string, index: number) => {
                          return (
                            <DstButton
                              btnClass="me-2 mb-3"
                              variant="secondary"
                              clickFunction={handleOpenLink(key, value)}
                              key={`${key}-${value}-${index}`}
                              value={value}
                              btnImageAfter={
                                key !== "aliases" ? <i className="dst-icon-link-external-02 ms-1"></i> : undefined
                              }
                            />
                          );
                        })}
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
            <h4>{translate(language, "PAGES.MESSAGES.MODALE.CONTENT")}</h4>
            <div className="p-3 megium-gray-background border-rounded">
              <div id="message-popup-content" dangerouslySetInnerHTML={{ __html: popupMessage.content }} />
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default Messages;
