import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";

import { getNestedProperty } from "../../services/UtilityService";
import { DstTableColumns, DstTableProps } from "./DstTable.d";
import "./DstTable.scss";

const _prepareHeader = (input: Array<any>) => {
  let result: any[] = [];

  input.forEach((element: any) => {
    if (element.key) {
      result[0] = [...(result[0] ?? []), element];
    } else if (element.children) {
      result[0] = [
        ...(result[0] ?? []),
        {
          name: element.name,
          size: element.children.length,
        },
      ];
      result[1] = [...(result[1] ?? []), element.children].flat();
    }
  });
  return result;
};

export const DstTable = ({
  classLine,
  columns,
  data,
  onClick,
  onRowSelect,
  selectable = false,
  striped = true,
  stylishLine,
  selectedItems = [],
}: DstTableProps) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: "ascending" | "descending";
    sortCompute?: Function;
  }>({
    key: null,
    direction: "ascending",
  });

  const [selectedRows, setSelectedRows] = useState<any[]>(selectedItems);

  useEffect(() => {
    if (
      selectable &&
      selectedRows
        .map((elem) => elem.id)
        .sort()
        .toString() !==
        selectedItems
          .map((elem) => elem.id)
          .sort()
          .toString()
    ) {
      setSelectedRows(selectedItems);
    }
  }, [selectable, selectedRows, selectedItems]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, item: any) => {
    event.stopPropagation();
    const newSelectedRows = selectedRows.map((row) => row.id).includes(item.id)
      ? selectedRows.filter((row) => row.id !== item.id)
      : [...selectedRows, item];

    setSelectedRows(newSelectedRows);
    onRowSelect?.(newSelectedRows);
  };

  const handleSelectAllChange = () => {
    const elements = selectedRows.length === data.length ? [] : data;
    setSelectedRows(elements);
    onRowSelect?.(elements);
  };

  const requestSort = (column: DstTableColumns) => {
    setSortConfig((currentSortConfig) => ({
      key: column.key!,
      sortCompute: column?.sortCompute,
      direction:
        currentSortConfig.key === column.key && currentSortConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const sortItems = (items: any[], key: string, direction: string) => {
    return items.sort((a, b) => {
      const aValue = getNestedProperty(a, key) || "";
      const bValue = getNestedProperty(b, key) || "";
      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "ascending" ? aValue - bValue : bValue - aValue;
      }
      return direction === "ascending"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  };

  const tableHeaders = useMemo(() => {
    return _prepareHeader(columns);
  }, [columns]);

  const sortedItems = useMemo(() => {
    let tmpData = [...data];
    if (!!sortConfig.sortCompute && !!sortConfig.key) {
      tmpData = tmpData.map((elem) => ({
        ...elem,
        [sortConfig.key!]: sortConfig.sortCompute!(elem),
      }));
    }
    return sortConfig.key ? sortItems(tmpData, sortConfig.key, sortConfig.direction) : tmpData;
  }, [data, sortConfig]);

  return (
    <Table striped={striped} bordered responsive className="s-regular dst-table" hover={!!onClick}>
      <thead>
        <tr>
          {selectable && (
            <th className="checkbox-cell text-center align-content-center">
              <Form.Check
                type="checkbox"
                checked={selectedRows.length === data.length && data.length > 0}
                onChange={handleSelectAllChange}
              />
            </th>
          )}
          {tableHeaders.map((row: any, rowIndex: number) => (
            <React.Fragment key={`header-row-${rowIndex}`}>
              {row.map((column: any, colIndex: number) => (
                <th
                  key={`header-col-${rowIndex}-${colIndex}`}
                  rowSpan={column.size ? 1 : 2}
                  colSpan={column?.size ?? 1}
                  onClick={() => requestSort(column)}
                  className="clickable align-content-center border-table"
                  style={column.stylishTitle ? column.stylishTitle() : {}}
                >
                  {column.name}
                  {sortConfig.key === column.key && (
                    <span
                      className={`ms-2 ${
                        sortConfig.direction === "descending"
                          ? "dst-icon-chevron-down-double"
                          : "dst-icon-chevron-up-double"
                      }`}
                    />
                  )}
                </th>
              ))}
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedItems.map((item, rowIndex) => (
          <tr
            key={item.id || `row-${rowIndex}`}
            onClick={(event) => onClick?.(event, item)}
            className={`${onClick ? "clickable" : ""} ${classLine?.(item) || ""}`}
          >
            {selectable && (
              <td className="text-center align-content-center" onClick={(e) => e.stopPropagation()}>
                <Form.Check
                  type="checkbox"
                  checked={selectedRows.map((row) => row.id).includes(item.id)}
                  onChange={(event) => handleCheckboxChange(event, item)}
                />
              </td>
            )}
            {tableHeaders
              .flat()
              .filter((element: any) => !!element.key)
              .map((element: any, cellIndex: number) => (
                <td
                  key={`data-${rowIndex}-${cellIndex}`}
                  style={{ ...stylishLine?.(item), ...element?.stylishCell?.(item) }}
                >
                  {element.render ? element.render(item) : getNestedProperty(item, element.key)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DstTable;
