import React, { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { useSession } from "../../../contexts/SessionContext";
import { formatSecondsMinutely } from "../../../services/TimeService";
import translate from "../../../services/Translate";

const HtmlEvaluationRender = ({
  evaluationRender,
  evaluationInfos,
  isCorrectionStarted,
  handleStatusChange,
  setTotalQuestions,
  setCorrectQuestions,
  correctQuestions,
}: any) => {
  const { language } = useSession();

  useEffect(() => {
    if (Array.isArray(evaluationRender) && evaluationRender.length > 0) {
      setTotalQuestions(evaluationRender.length);
      setCorrectQuestions(evaluationRender.filter((item: any) => item.is_correct === true).length);
    } // eslint-disable-next-line
  }, [evaluationRender]);

  if (!Array.isArray(evaluationRender) || evaluationRender.length === 0) {
    return <p> {translate(language, "PAGES.EVAL.DATA_NOT_AVAILABLE")}</p>;
  }

  const renderAnswer = (answer: any) => {
    if (typeof answer == "string") {
      return <p className="mt-3 white-background s-regular p-3 border">{answer}</p>;
    } else if (!Array.isArray(answer)) {
      return <p className="mt-3 white-background s-regular p-3 border">{JSON.stringify(answer)}</p>;
    } else {
      return (
        <div className="answers d-flex flex-wrap mt-3 s-regular">
          {answer.map((item: any, index: any) => (
            <div
              key={index}
              className={`p-2 ${
                item.chosen ? "blue-background white-font" : "white-background"
              } me-2 mb-2 px-3 py-2 border rounded`}
            >
              {item.answer}
            </div>
          ))}
        </div>
      );
    }
  };

  const renderIllustration = (illustrations: any) => {
    if (!illustrations || illustrations.length === 0 || illustrations[0] === "[]") return null;

    try {
      const parsedIllustrations = JSON.parse(illustrations[0]);
      return parsedIllustrations.map((imgTag: any, index: any) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: imgTag }} />
      ));
    } catch (e) {
      return null;
    }
  };

  return (
    <React.Fragment>
      {evaluationRender.map((item: any, index: any) => (
        <div key={index} className="evaluation-item m-3">
          <div className="d-flex">
            <div className="me-3 m-bold size-icon">
              <DropdownButton
                id={`dropdown-correction-button-${index}`}
                title={
                  item.is_correct === null ? (
                    <i className="dst-icon-pencil-line" />
                  ) : item.is_correct ? (
                    <i className="dst-icon-check text-success" />
                  ) : (
                    <i className="dst-icon-x-close text-danger" />
                  )
                }
                className="dropdown-correction-button text-center"
                variant={item.is_correct === null ? "Warning" : item.is_correct ? "Success" : "Danger"}
                disabled={!isCorrectionStarted || !item.isEditable || evaluationInfos.correctionSubmitted}
              >
                <Dropdown.Item
                  onClick={() => {
                    handleStatusChange(true, index);
                    setCorrectQuestions(correctQuestions + 1);
                  }}
                  className="d-flex align-items-center"
                >
                  <i className="me-2 dst-icon-check text-success" />
                  {translate(language, "PAGES.EVAL.DROPDOWN.CORRECT")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleStatusChange(false, index)} className="d-flex align-items-center">
                  <i className="me-2 dst-icon-x-close text-danger" />
                  {translate(language, "PAGES.EVAL.DROPDOWN.UNCORRECT")}
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="w-100">
              <h4 className="m-bold">
                {item.resume}{" "}
                <span className="gray-font xs-regular ms-3">
                  {evaluationInfos.dt_start
                    ? formatSecondsMinutely(
                        Math.floor((item.timestamp - new Date(evaluationInfos.dt_start).getTime()) / 1000)
                      )
                    : "N/A"}
                </span>
              </h4>
              {renderIllustration(item.illustration)}
              {renderAnswer(item.answer)}
            </div>
          </div>
          <hr />
        </div>
      ))}
    </React.Fragment>
  );
};

export default HtmlEvaluationRender;
