import { ExamStatus } from "../models/Exam";

export const EXAM_STATUS_ICON = {
  [ExamStatus.SUCCESS]: <i className="dst-icon-check text-success me-2" />,
  [ExamStatus.REPASS]: <i className="dst-icon-x-close text-danger me-2" />,
  [ExamStatus.FAILED]: <i className="dst-icon-x-close text-danger me-2" />,
  [ExamStatus.PRE_CORRECTED]: <i className="dst-icon-cpu-chip-01 orange-font me-2" />,
  default: <i className="dst-icon-hourglass-01 me-2" />,
};

export const getExamStatusIcon = (status: ExamStatus) => {
  return (EXAM_STATUS_ICON as any)[status] || EXAM_STATUS_ICON.default;
};
